@import "GlobalStyles/functions";

.limitWidth {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    text-wrap: normal;
    max-width: 100%;
    direction: ltr;
}
